<template>
  <v-app>
    <v-app-bar color="primary">
      <v-app-bar-title style="cursor: pointer" @click="redirect()">
        SMPW
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <template v-if="appStore.isRegistered">
        <v-btn icon flat @click="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <template v-else-if="!appStore.isRegistered">
        <v-btn :to="{ name: 'Login' }"> Login </v-btn>
      </template>
    </v-app-bar>
    <v-main>
      <v-navigation-drawer
        v-if="appStore.isRegistered"
        v-model="drawer"
        location="right"
        temporary
      >
        <v-list nav>
          <v-list-item
            v-for="route in drawerRoutes"
            :key="route.name"
            :to="{ name: route.routeName }"
            :title="route.name"
            :prepend-icon="route.icon"
            link
          >
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list nav>
          <v-list-item
            title="Logout"
            prepend-icon="mdi-logout"
            link
            @click="logout()"
          >
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <router-view></router-view>
    </v-main>
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="snackbarColor"
      location="bottom"
      :vertical="true"
    >
      {{ snackbarText }}
    </v-snackbar>
  </v-app>
</template>

<script setup lang="ts">
import { onBeforeMount, ref, computed } from "vue";
import { useAppStore } from "@/store/app";
import { useRouter } from "vue-router";
import useEmitter from "@/composables/useEmitter";

import axios from "@/axios";

const emitter = useEmitter();

const snackbar = ref(false);
const snackbarText = ref("");
const snackbarColor = ref("");

const drawer = ref(false);
const drawerRoutes = computed(() => [
  { name: "Schedules", icon: "mdi-calendar", routeName: "Schedules" },
  { name: "Users", icon: "mdi-account", routeName: "Users" },
  { name: "SMPW Locations", icon: "mdi-map-marker", routeName: "Sites" },
  { name: "Volunteers", icon: "mdi-account-group", routeName: "Volunteers" },
  { name: "Congregations", icon: "mdi-home-heart", routeName: "Congregations" },
  { name: "Security Questions", icon: "mdi-lock-open", routeName: "Questions" },
]);

emitter.on("error", (message: string) => {
  snackbarText.value = message;
  snackbarColor.value = "error";
  snackbar.value = true;
});

emitter.on("success", (message: string) => {
  snackbarText.value = message;
  snackbarColor.value = "success";
  snackbar.value = true;
});

const appStore = useAppStore();
const router = useRouter();

const fetchProfile = async () => {
  if (!localStorage.getItem("token")) {
    return;
  }
  try {
    await appStore.setProfile();
  } catch (error) {
    if (localStorage.getItem("token")) {
      await logout();
    }
    console.log(error);
  }
};

onBeforeMount(async () => {
  await fetchProfile();
  if (!localStorage.getItem("token")) {
    router.push({ name: "Verification" });
  }
});

const redirect = () => {
  if (!localStorage.getItem("token")) {
    router.push({ name: "Verification" });
  } else {
    router.push({ name: "Schedules" });
  }
};

const logout = async () => {
  try {
    await axios.post("/api/users/logout/");
  } catch (error) {
    console.log(error);
  } finally {
    appStore.$reset();
    localStorage.removeItem("token");
    router.push({ name: "Verification" });
  }
};
</script>

<style lang="scss">
$freeSchedule: #4caf50;
$highAvailability: lighten($freeSchedule, 20%);
</style>
