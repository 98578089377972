// Composables
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "Home",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
      },
    ],
  },
  {
    path: "/verification",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "Verification",
        component: () => import("@/views/verification/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "Login",
        component: () => import("@/views/login/index.vue"),
      },
    ],
  },
  {
    path: "/schedules",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "Schedules",
        component: () => import("@/views/schedules/index.vue"),
      },
      {
        path: ":id",
        name: "Schedule",
        component: () => import("@/views/schedules/_id.vue"),
      },
    ],
  },
  {
    path: "/volunteers",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "Volunteers",
        component: () => import("@/views/volunteers/index.vue"),
      },
      {
        path: "create",
        name: "CreateVolunteer",
        component: () => import("@/views/volunteers/create.vue"),
      },
      {
        path: ":id",
        name: "Volunteer",
        component: () => import("@/views/volunteers/_id.vue"),
      },
    ],
  },
  {
    path: "/congregations",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "Congregations",
        component: () => import("@/views/congregations/index.vue"),
      },
      {
        path: "create",
        name: "CreateCongregation",
        component: () => import("@/views/congregations/create.vue"),
      },
      {
        path: ":id",
        name: "Congregation",
        component: () => import("@/views/congregations/_id.vue"),
      },
    ],
  },
  {
    path: "/sites",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "Sites",
        component: () => import("@/views/sites/index.vue"),
      },
      {
        path: "create",
        name: "CreateSite",
        component: () => import("@/views/sites/create.vue"),
      },
      {
        path: ":id",
        name: "Site",
        component: () => import("@/views/sites/_id.vue"),
      },
    ],
  },
  {
    path: "/users",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "Users",
        component: () => import("@/views/users/index.vue"),
      },
      {
        path: "create",
        name: "CreateUser",
        component: () => import("@/views/users/create.vue"),
      },
      {
        path: ":id",
        name: "User",
        component: () => import("@/views/users/_id.vue"),
      },
    ],
  },
  {
    path: "/questions",
    component: () => import("@/layouts/default/Default.vue"),
    children: [
      {
        path: "",
        name: "Questions",
        component: () => import("@/views/questions/index.vue"),
      },
      {
        path: "create",
        name: "CreateQuestion",
        component: () => import("@/views/questions/create.vue"),
      },
      {
        path: ":id",
        name: "Question",
        component: () => import("@/views/questions/_id.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
