// Utilities
import { defineStore } from "pinia";
import axios from "@/axios";

export const useAppStore = defineStore("app", {
  state: () => ({
    user: null,
    isLoggedIn: false,
    isRegistered: false,
    isAdmin: false,
    permissions: [],
  }),

  actions: {
    async setProfile() {
      const { data } = await axios.get("/api/users/me/");
      this.isLoggedIn = true;
      this.isRegistered = !data.is_dummy;
      this.isAdmin = data.is_staff;
    },
  },
});
